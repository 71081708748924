//添加排课时段的弹框
<template>
    <div class="add_class_time_dialog">
        <CommonDialog @close="close" :isCenter="isCenter" :showDialog="showDialog" :title="title" :width="width">
            <template v-slot:content>
                <div class="dialog_content">

                    <!-- 间隔线 -->
                    <el-divider></el-divider>

                    <!-- 开始时间 -->
                    <div class="flex_h margin_top_30">
                        <span class="margin_right_10">开始时间</span>
                        <el-select v-model="startTimeH" placeholder="请选择">
                            <el-option v-for="(item,index) in startTimeHArr" :key="index" :label="item" :value="item">
                            </el-option>
                        </el-select>
                        <span class="margin_left_30 margin_right_10">时</span>

                        <el-select v-model="startTimeM" placeholder="请选择">
                            <el-option v-for="(item,index) in startTimeMArr" :key="index" :label="item" :value="item">
                            </el-option>
                        </el-select>
                        <span class="margin_left_10">分</span>
                    </div>

                    <!-- 结束时间 -->
                    <div class="flex_h margin_top_30">
                        <span class="margin_right_10">结束时间</span>
                        <el-select v-model="endTimeH" placeholder="请选择">
                            <el-option v-for="(item,index) in endTimeHArr" :key="index" :label="item" :value="item">
                            </el-option>
                        </el-select>
                        <span class="margin_left_30 margin_right_10">时</span>

                        <el-select v-model="endTimeM" placeholder="请选择">
                            <el-option v-for="(item,index) in endTimeMArr" :key="index" :label="item" :value="item">
                            </el-option>
                        </el-select>
                        <span class="margin_left_10">分</span>
                    </div>

                    <!-- 占位 -->
                    <div class="flex_1"></div>

                    <!-- 替换按钮  -->
                    <div class='com_dv'>
                        <el-button :loading="showLoading" :class="['com_bt_action','commit_bt',isArrowSearch?'search_bt_active':'search_bt_normal']" @click.stop="doSaveAction">保&nbsp;&nbsp;&nbsp;&nbsp;存</el-button>
                    </div>
                </div>
            </template>
        </CommonDialog>
    </div>
</template>
<script>
import { hourArr, minuteArr } from "../../utils/TimeArr.js";
export default {
    data() {
        return {
            title: "新增上课时段",

            isCenter: false,

            width: "480px",

            //是否显示loading
            isShowLoading: false,

            //开始时间时
            startTimeH: "00",

            //开始时间的时数组
            startTimeHArr: [],

            //开始时间分
            startTimeM: "00",

            //开始时间的分数组
            startTimeMArr: [],

            //结束时间的时数组
            endTimeHArr: [],

            //结束时间时
            endTimeH: "00",

            //结束时间的分数组
            endTimeMArr: [],

            //结束时间分
            endTimeM: "00",

            //确定按钮是否高亮
            isArrowSearch: false,

            //显示加载框
            showLoading: false,
        };
    },
    created() {
        //初始化开始时间的时,分,结束时间的时,分数组
        this.initArr();
    },
    methods: {
        //初始化时分数组
        initArr() {
            this.startTimeHArr = this.startTimeHArr.concat(hourArr);
            this.startTimeMArr = this.startTimeMArr.concat(minuteArr);
            this.endTimeHArr = this.startTimeHArr.concat(hourArr);
            this.endTimeMArr = this.startTimeMArr.concat(minuteArr);
        },
        //关闭的方法
        close() {
            this.$emit("close");
        },

        //确定
        doSaveAction() {
            //如果选择了开始时间和结束时间的时才允许提交
            if (this.startTimeH != "00" && this.endTimeH != "00") {
                //判断一下结束时间是否大于开始时间
                let end = `2022:${this.endTimeH}:${this.endTimeM}`;
                let start = `2022:${this.startTimeH}:${this.startTimeM}`;
                if (this.getTimestamp(end) <= this.getTimestamp(start)) {
                    this.showWarnMsg("选择的结束时间必须大于开始时间");
                } else {
                    // this.$emit("updateTime", {
                    //     startTime: `${this.startTimeH}:${this.startTimeM}`,
                    //     endTime: `${this.endTimeH}:${this.endTimeM}`,
                    // });
                    this.httpData();
                }
            }
        },

        //请求新增时段接口
        httpData() {
            this.showLoading = true;
            let param = this.getHttpParams();
            param.type = 11;
            param.startTime = `${this.startTimeH}:${this.startTimeM}`;
            param.endTime = `${this.endTimeH}:${this.endTimeM}`;
            this.$http
                .fetchPost(this.$api.LESSONMANAGER, param)
                .then((res) => {
                    let result = res.data;
                    if (result.state == 200) {
                        //增加成功,关闭
                        this.close();
                        this.showLoading = false;
                        this.$emit("updateTime");
                    } else {
                        this.showLoading = false;
                        this.showErrorMsg(res.data.msg);
                    }
                })
                .catch((err) => {
                    this.showLoading = false;
                    this.showErrorMsg("发生异常，新增失败");
                });
        },
    },
    computed: {},
    components: {},
    props: ["showDialog"],
    computed: {
        listenChange() {
            const { startTimeH, endTimeH } = this;
            return { startTimeH, endTimeH };
        },
    },
    watch: {
        listenChange(val) {
            //只有选择了开始时间和结束时间时,才允许进行保存
            if (val.startTimeH != "00" && val.endTimeH != "00") {
                this.isArrowSearch = true;
            } else {
                this.isArrowSearch = false;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.add_class_time_dialog {
    .dialog_content {
        height: 240px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .com_dv {
            width: 100%;
            align-items: center;
            justify-content: center;
            display: flex;
            .search_bt_active {
                background: #409eff;
                color: white;
                cursor: pointer;
            }

            .search_bt_normal {
                background: #cfd1d4;
                color: white;
                cursor: not-allowed;
            }

            .commit_bt {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 32px;
                width: 120px;
                position: absolute;
                bottom: 14px;
                left: 50%;
                transform: translateX(-50%);
                border: none;
            }
        }

        .msg_info {
            color: red;
        }
        ::v-deep .el-input__inner {
            height: 32px;
            line-height: 32px;
            padding-left: 6px;
        }

        ::v-deep .el-input__icon {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        ::v-deep .el-input {
            width: 80px;
        }

        ::v-deep .el-input__suffix {
            right: 0px;
        }
    }
}
</style>