//节假日设置
<template>
    <div class="set_holidays">
        <Layout :showLoading="showLoading">
            <template v-slot:content>
                <div class="set_holidays_content">
                    <!-- 日历展示区域和所选日期显示显示列表 -->
                    <div class="bom_content">
                        <!-- 日历展示区域 -->
                        <div class="left_calendar">
                            <!-- 顶部的年份切换以及保存按钮 -->
                            <div class="top_memu">
                                <!-- 中间的标题以及切换箭头 -->
                                <div class="arrow_time">
                                    <div @click.stop="doSubYear" :class="['top_arrow','el-icon-arrow-left', currYear==year?'off_cursor':'on_cursor']"></div>
                                    <div class="year_name">{{year}}年</div>
                                    <div @click.stop="doAddYear" class="on_cursor top_arrow el-icon-arrow-right"></div>
                                </div>
                            </div>
                            <!-- 日历展示区域 -->
                            <el-scrollbar>
                                <div class="calendar_content">
                                    <div class="item_calendar" v-for="(item,index) in monthArr" :key="index">
                                        <div class="calendar_content">
                                            <Calendar @onDayClickEvent="onDayClickEvent" :calendarObj="item"></Calendar>
                                        </div>
                                    </div>
                                </div>
                            </el-scrollbar>
                        </div>

                        <!-- 右边所选日期展示列表 -->
                        <div class="right_list">

                            <!--保存按钮 -->
                            <div class="right_save_bt">
                                <el-button size="medium" class="bt_next" type="primary" :loading="loading" @click.stop="saveHolidays">保存设置</el-button>
                                <span class='margin_left_14 tv_red'>设置完成请保存</span>
                            </div>

                            <!-- 选择的日期展示区域 -->
                            <div class="choose_content">

                                <el-scrollbar v-if="holidaysArr&&holidaysArr.length>0">
                                    <div class="choose_date_list">
                                        <div class="item_holidays" v-for="(item,index) in holidaysArr" :key="index">

                                            <!-- 日期 -->
                                            <div class="time_str">{{item.holidayDate}}</div>

                                            <!-- 输入框 -->
                                            <el-input maxlength="10" clearable placeholder="请输入节假日名称" v-model="item.holidayName"></el-input>

                                            <!-- 删除按钮 -->
                                            <div @click.stop='doDelHolidays(item,index)' class="el-icon-close del"></div>
                                        </div>
                                    </div>
                                </el-scrollbar>

                                <!-- 空视图 -->
                                <div class="empty_view" v-if="holidaysArr.length==0">
                                    <el-empty description="暂无节日数据"></el-empty>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </Layout>
    </div>
</template>
<script>
import Calendar from "@/components/calendar/Calendar.vue";
//引入日历初始化
import CalendarUtil from "@/mixin/CalendarUtil.js";
export default {
    mixins: [CalendarUtil],
    data() {
        return {
            //加载的loading
            showLoading: 1,

            //保存设置的loading框
            loading: false,

            //根据选择的年份创建当年12个月的日历数据
            monthArr: [],

            //切换的年份
            year: -1,

            //当前日期的年份
            currYear: -1,

            //右边的自定义节假日的数组
            holidaysArr: [],
        };
    },
    created() {
        //初始化数据
        this.initData();

        //请求服务器节假日数据
        this.httpData();
    },
    methods: {
        initData() {
            //获取当前的年份
            this.currYear = new Date().getFullYear();
            this.year = this.currYear;
        },

        //请求节假日数据
        httpData() {
            let param = this.getHttpParams();
            param.type = 13;
            this.$http
                .fetchPost(this.$api.LESSONMANAGER, param)
                .then((res) => {
                    //显示内容视图
                    this.showLoading = 1;
                    let result = res.data;

                    if (result.state == 200) {
                        //取出列表数据
                        let serverData = result.data;
                        //先清空一下数组
                        this.holidaysArr.splice(0, this.holidaysArr.length);
                        if (!this.isEmpty(serverData)) {
                            serverData.forEach((item) => {
                                //给每个条目绑定一个temp用于排序
                                item.temp = new Date(
                                    item.holidayDate
                                ).getTime();
                            });
                            this.holidaysArr =
                                this.holidaysArr.concat(serverData);
                        }
                        //根据年来初始化日历数据
                        this.sysCalendarData();
                    } else {
                        this.showErrorMsg(this.getMesage(res.data.state));
                    }
                })
                .catch((err) => {
                    this.showLoading = 1;
                    console.log("发生异常");
                });
        },

        //减年
        doSubYear() {
            if (this.year > this.currYear) {
                this.year--;
            }
            //在同步日历的数据
            this.sysCalendarData();
        },

        //加年
        doAddYear() {
            this.year++;
            //同步日历的数据
            this.sysCalendarData();
        },

        //同步日历的数据
        sysCalendarData() {
            this.monthArr.splice(0, this.monthArr.length);
            this.monthArr = this.monthArr.concat(
                this.initCalendarData(this.year, this.holidaysArr)
            );
        },

        //日历的天的点击事件
        onDayClickEvent(obj) {
            //请求接口查询当前日期是否有排课信息
            let param = this.getHttpParams();
            param.type = 15;
            param.date = obj.date;
            this.$http.fetchPost(this.$api.LESSONMANAGER, param).then((res) => {
                let result = res.data;
                if (result.state == 706) {
                    //当前日期存在排课记录
                    if (obj.isSelected) {
                        //原来是选中则提示不允许取消
                        this.showWarnMsg(
                            "当前日期已经存在排课记录,不能进行取消操作"
                        );
                    } else {
                        //原来是未选中则提示不允许添加
                        this.showWarnMsg(
                            "当前日期已经存在排课记录,不能设置为节假日"
                        );
                    }
                } else {
                    // 当前日期不存在排课记录;
                    //获取点击日历位置的日期2022-03-05
                    let date = obj.date;
                    //给一个时间戳用于排序
                    let temp = new Date(date).getTime();
                    //选中状态取反
                    obj.isSelected = !obj.isSelected;
                    //判断是选中还是取消
                    if (obj.isSelected) {
                        //选中,将对一个的日期创建一个新元素添加到holidaysArr,并进行排序
                        let obj = { holidayDate: date, holidayName: "", temp };
                        this.holidaysArr.push(obj);
                        // console.log("节假日数据是:", this.holidaysArr);
                        //排序
                        this.holidaysArr.sort(this.sortArr("temp"));
                    } else {
                        //取消,将对应日期的元素从节日数组holidaysArr删除
                        let index = this.holidaysArr.findIndex((item) => {
                            return item.holidayDate == date;
                        });
                        this.holidaysArr.splice(index, 1);
                    }
                }
            });
        },

        //假期设置的删除事件
        doDelHolidays(item, index) {
            //1:从节日数组holidaysArr中删除当前元素
            this.holidaysArr.splice(index, 1);

            //2:根据删除的元素去同步左侧日历数据中对应天的状态,删除过后对应天的状态就是未选中状态了
            //取出日期 2022-03-03类似这种格式
            let date = item.holidayDate;
            for (let i = 0; i < this.monthArr.length; i++) {
                let dayArr = this.monthArr[i].dayArr;
                let pos = dayArr.findIndex((dayItem) => {
                    return dayItem.date == date;
                });
                if (pos != -1) {
                    dayArr[pos].isSelected = false;
                    break;
                }
            }
        },

        //保存节假日
        saveHolidays() {
            if (this.isEmpty(this.holidaysArr)) {
                this.showWarnMsg("请先选择节假日日期后再进行保存");
                return;
            }

            //判断节假日是否设置完全
            if (!this.hasSettingFinished()) {
                this.showWarnMsg("有相对应的日期还未设置节假日名称,请核查~");
                return;
            }

            //请求服务器上传节假日
            this.loading = true;
            let param = this.getHttpParams();
            param.type = 14;
            //拼接保存节假日的参数
            let obj = {};
            obj.schoolId = this.getUserInfo().schoolId;
            obj.userId = this.getUserInfo().id;
            obj.content = this.holidaysArr;
            param.content = JSON.stringify(obj);
            this.$http
                .fetchPost(this.$api.LESSONMANAGER, param)
                .then((res) => {
                    this.loading = false;
                    let result = res.data;
                    if (result.state == 200) {
                        this.showSuccessMsg("保存成功");
                    } else {
                        this.showErrorMsg(`${res.data.msg},保存失败`);
                    }
                })
                .catch((err) => {
                    this.loading = false;
                    console.log("发生异常");
                });
        },

        //是否设置完日期的节假日名称
        hasSettingFinished() {
            // console.log("节假日数组是：", this.holidaysArr);

            let res = true;
            for (let i = 0; i < this.holidaysArr.length; i++) {
                if (this.isEmpty(this.holidaysArr[i].holidayName)) {
                    res = false;
                    break;
                }
            }
            return res;
        },
    },
    computed: {},
    components: {
        Calendar,
    },
};
</script>
<style lang="scss" scoped>
.set_holidays {
    min-width: 1200px;
    height: calc(100vh - 76px - 60px);

    .set_holidays_content {
        .top_memu {
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            .arrow_time {
                display: flex;
                align-items: center;
                justify-content: center;
                .top_arrow {
                    font-size: 16px;
                }
                .year_name {
                    margin: 0 14px;
                    color: black;
                    font-weight: bold;
                }

                //禁用的鼠标样式
                .off_cursor {
                    cursor: not-allowed;
                }

                .on_cursor {
                    cursor: pointer;
                }
            }

            .right_content {
                position: absolute;
                right: 12px;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        //底部的内容部分
        .bom_content {
            display: flex;
            .el-scrollbar {
                height: calc(100vh - 76px - 60px - 50px);
            }

            ::v-deep .el-scrollbar__wrap {
                overflow-y: auto;
                overflow-x: hidden;
            }

            //日历展示区域
            .left_calendar {
                width: 72%;
                .calendar_content {
                    display: flex;
                    justify-content: start;
                    flex-wrap: wrap;
                    overflow-y: auto;

                    .item_calendar {
                        width: 33.33%;
                        display: flex;
                        margin-top: 14px;
                        margin-bottom: 10px;
                        align-items: center;
                        justify-content: center;

                        .calendar_content {
                            width: 95%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
            }

            //右边展示选中日期的列表部分
            .right_list {
                width: 28%;
                height: calc(100vh - 76px - 60px);
                display: flex;
                flex-direction: column;
                .right_save_bt {
                    height: 50px;
                    margin-left: 20px;
                    margin-right: 20px;
                }
                .choose_content {
                    flex: 1;
                    position: relative;
                    margin-left: 20px;
                    margin-right: 10px;

                    .choose_date_list {
                        display: flex;
                        flex-direction: column;
                        margin-top: 10px;

                        .item_holidays {
                            height: 50px;
                            display: flex;
                            align-items: center;

                            //时间
                            .time_str {
                                font-weight: bold;
                                margin-right: 20px;
                            }

                            ::v-deep .el-input__inner {
                                height: 32px;
                                line-height: 32px;
                                padding-left: 6px;
                            }

                            ::v-deep .el-input__icon {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                            ::v-deep .el-input {
                                width: 160px;
                            }

                            ::v-deep .el-input__suffix {
                                right: 0px;
                            }

                            //删除
                            .del {
                                margin-left: 20px;
                                color: #409eff;
                                cursor: pointer;
                                font-size: 20px;
                            }
                        }
                    }

                    .empty_view {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }
    }
}
</style>