<template>
  <div class="edit_class_time">
    <!-- 视图部分 -->
    <Layout :showLoading="showLoading">
      <template v-slot:content>
        <!-- 排课时段 -->
        <div class="edit_class_time_content">
          <!-- 顶部的导航 -->
          <TopNavBar :tabArr="tabArr" @onItemClick="onItemClick"></TopNavBar>

          <!-- 排课时段 -->
          <div v-if="curPos == 0">
            <!-- 新增,删除 -->
            <div class="flex_h margin_top_14 margin_bom_10">
              <el-button class="aciton_bt has_padding search_bt_active com_bt_action" @click.stop="doAddAction"> 新&nbsp;&nbsp;增 </el-button>

              <el-button
                v-if="showDelBt"
                :loading="showDelLoading"
                class="aciton_bt has_padding search_bt_active com_bt_action margin_left_30"
                @click.stop="doDelAction"
              >
                删&nbsp;&nbsp;除
              </el-button>
            </div>

            <!-- 表格部分 -->
            <div class="dv_table_view_content">
              <el-table style="width: 50%" border stripe :data="tableData" :max-height="maxHeight">
                <!-- 选中按钮  -->
                <el-table-column align="center" width="60" label="操作">
                  <template slot-scope="scope">
                    <!-- scope.row.type==1存在排课  v-if="scope.row.isShow"-->
                    <span
                      @click.stop="doSelectAction(scope.row)"
                      :class="[
                        'common_box',
                        'iconfont',
                        scope.row.selected ? 'icon-checkbox' : 'icon-CheckboxUnchecked',
                        { unableSelect: scope.row.type == 1 },
                      ]"
                    ></span>
                  </template>
                </el-table-column>

                <el-table-column prop="startTime" align="center" label="开始时分" min-width="50%"></el-table-column>

                <el-table-column prop="endTime" align="center" label="结束时分" min-width="50%"></el-table-column>
              </el-table>
            </div>
          </div>

          <!-- 设置节假日的组件 -->
          <SetHolidays v-if="curPos == 1"></SetHolidays>

          <!-- 新增上课时段的弹框 -->
          <AddClassTimeDialog
            @updateTime="updateTime"
            v-if="isShowAddClassTimeDialog"
            @close="isShowAddClassTimeDialog = false"
            :showDialog="isShowAddClassTimeDialog"
          ></AddClassTimeDialog>
        </div>
      </template>
    </Layout>
  </div>
</template>

<script>
import SetHolidays from "@/views/educationalcenter/coursemanager/SetHolidays.vue";
//新增上课时段的弹框
import AddClassTimeDialog from "@/components/dialog/AddClassTimeDialog.vue";
export default {
  data() {
    return {
      //顶部的导航条
      tabArr: ["排课时段", "节假日设置"],

      //是否显示新增上课时段的弹框
      isShowAddClassTimeDialog: false,

      //导航栏的选中位置
      curPos: 0,

      //表格的最大高度
      maxHeight: 0,

      //表格数据
      tableData: [],

      //选中数组
      recordSelection: [],

      //加载状态
      showLoading: -1,

      //删除的loading
      showDelLoading: false,

      //是否显示删除按钮
      showDelBt: false,
    };
  },
  created() {
    this.initData();

    //请求页面数据
    this.httpData();
  },
  methods: {
    //初始化表格最大高度
    initData() {
      let clientH = document.documentElement.clientHeight;
      this.maxHeight = clientH - 76 - 120;
    },

    //新增
    doAddAction() {
      this.isShowAddClassTimeDialog = true;
    },

    //删除
    doDelAction() {
      this.showDelLoading = true;
      let param = this.getHttpParams();
      param.type = 12;
      param.id = this.getDelIds();
      this.$http
        .fetchPost(this.$api.LESSONMANAGER, param)
        .then((res) => {
          let result = res.data;
          if (result.state == 200) {
            this.showDelLoading = false;

            //将记录的数组清空
            this.recordSelection.splice(0, this.recordSelection.length);

            //刷新页面数据
            this.httpData();
          } else {
            this.showDelLoading = false;
            this.showWarnMsg(`${res.data.msg},删除失败`);
          }
        })
        .catch((err) => {
          this.showDelLoading = false;
          this.showErrorMsg("发生异常，删除失败");
        });
    },

    // 排课时段和节假日切换的点击事件
    onItemClick(pos) {
      this.curPos = pos;
    },

    //新增上课时段
    updateTime(obj) {
      //刷新页面
      this.httpData();

      //根据开始时间和结束时间来确定需要插入tableData的位置
      //先判断数组中是否有当前时间段
      // if (this.isExustTimeRange(obj)) {
      //     //已经存在当前时间段
      //     this.showWarnMsg("已经存在当前排课时段,请勿重复添加");
      // } else {
      //     //数组中没有当前时间段
      //     this.tableData.push(obj);
      //     //对表格数据进行排序处理
      //     this.tableData.sort((a, b) => {
      //         let startA = this.getTimestamp(`2022:${a.startTime}`);
      //         let startB = this.getTimestamp(`2022:${b.startTime}`);
      //         let endA = this.getTimestamp(`2022:${a.endTime}`);
      //         let endB = this.getTimestamp(`2022:${b.endTime}`);
      //         if (startA != startB) {
      //             //如果开始时间不相等,按开始时间排
      //             return startA - startB;
      //         } else {
      //             //如果开始时间相等,按结束时间排
      //             return endA - endB;
      //         }
      //     });
      // }
    },

    //判断是否存在时间区间段
    isExustTimeRange(obj) {
      let res = false;
      if (!this.isEmpty(this.tableData)) {
        for (let i = 0; i < this.tableData.length; i++) {
          let item = this.tableData[i];
          if (item.startTime == obj.startTime && item.endTime == obj.endTime) {
            res = true;
            break;
          }
        }
      }
      return res;
    },

    //请求数据
    httpData() {
      let param = this.getHttpParams();
      param.type = 10;
      this.$http
        .fetchPost(this.$api.LESSONMANAGER, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = 1;
          let result = res.data;
          if (result.state == 200) {
            //取出列表数据
            let serverData = result.data;
            //先清空一下数组
            this.tableData.splice(0, this.tableData.length);
            if (!this.isEmpty(serverData)) {
              serverData.forEach((item) => {
                //设置是否允许选中的标识
                if (item.type == 1) {
                  //存在排课
                  this.$set(item, "isShow", false);
                } else {
                  //不存在排课
                  this.$set(item, "isShow", true);
                  //设置选中状态,默认都是false
                  this.$set(item, "selected", false);
                }
              });
              this.tableData = this.tableData.concat(serverData);
            }
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("发生异常");
        });
    },

    //条目的选中和取消事件
    doSelectAction(item) {
      if (item.type == 1) {
        //存在排课,不能进行操作
        // this.showWarnMsg("当前时段已经存在排课信息,不能进行操作");
        return;
      }
      //状态取反
      item.selected = !item.selected;

      //在同步一下存储状态
      if (item.selected) {
        //选中,添加到保存的数组
        this.recordSelection.push(item);
      } else {
        //取消的时候将当前元素从记录的数组中删除
        this.recordSelection.splice(this.recordSelection.findIndex((obj) => obj.timeId === item.timeId));
      }
    },

    //获取选中元素的拼接id
    getDelIds() {
      let idsArr = this.recordSelection.map((item) => item.timeId);
      return idsArr.join("-");
    },
  },
  watch: {
    recordSelection(newValue) {
      if (!this.isEmpty(newValue)) {
        this.showDelBt = true;
      } else {
        this.showDelBt = false;
      }
    },
  },
  components: {
    SetHolidays,
    AddClassTimeDialog,
  },
};
</script>

<style lang="scss" scoped>
.edit_class_time {
  margin: 0 14px;
  height: calc(100vh - 78px);
  display: flex;
  flex-direction: column;
  .edit_class_time_content {
    display: flex;
    flex-direction: column;

    .common_box {
      cursor: pointer;
      font-size: 18px;
      color: #409eff;
    }

    .unableSelect {
      color: #dcdfe6;
      cursor: not-allowed;
      // pointer-events: none;
    }

    .has_padding {
      padding: 0 10px;
    }

    .aciton_bt {
      border: none;
      display: flex;
      align-content: center;
      justify-content: center;
      background: #409eff;
      color: white;
    }

    //表格内容部分
    .dv_table_view_content {
      flex: 1;
      min-width: 1200px;
      margin: 10px 0;
      .bt_aciton {
        padding: 3px 14px;
        height: 24px;
        margin: 0 10px;
        border: 1px solid #409eff;
      }
    }
  }
}
</style>
