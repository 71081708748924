// 自定义的日历组件
<template>
    <div class="custom_calendar">
        <!-- 年月  -->
        <div class="head_content">
            <span class="year">{{calendarObj.year}}</span>
            <span class="month margin_left_12">{{calendarObj.month}}</span>
        </div>
        <!-- 星期几 -->
        <div class="week_day">
            <div :class="['week_info',{tv_red:index==0||index==6}]" v-for="(item,index) in weekArr" :key="index">{{item}}</div>
        </div>

        <!-- 日期展示 -->
        <div class="day_content">
            <div class="day_info" v-for="(item,index) in calendarObj.dayArr" :key="index">
                <span @click.stop="onClickDay(item)" :class="['day_content',{day_selected:item.isSelected},'cursor_p']">{{item.dayValue}}</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            weekArr: ["日", "一", "二", "三", "四", "五", "六"],
        };
    },
    created() {},
    methods: {
        /**
         * 日期的点击事件
         */
        onClickDay(item) {
            if (this.isEmpty(item.dayValue)) {
                //当前位置没有日期值点击无效果
                return;
            }
            //调用父组件的事件
            this.$emit("onDayClickEvent", item);
        },

        /**
         * 根据中文月份获取阿拉布数字月份
         */
        getMonthIndexByValue(monthValue) {
            let monthArr = [
                "一月",
                "二月",
                "三月",
                "四月",
                "五月",
                "六月",
                "七月",
                "八月",
                "九月",
                "十月",
                "十一月",
                "十二月",
            ];

            let index = monthArr.findIndex((item) => {
                return item == monthValue;
            });

            let res = index + 1;
            if (res < 10) {
                res = "0" + res;
            }
            return res;
        },
    },
    computed: {},
    components: {},
    //需要传入年和月
    props: ["calendarObj"],
};
</script>
<style lang="scss" scoped>
.custom_calendar {
    width: 94%;
    border: 1px solid #d7d7d7;
    border-radius: 2px;
    .head_content {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        .year {
            font-weight: bold;
        }
    }
    .week_day {
        width: 100%;
        height: 40px;
        display: flex;
        background: rgba(0, 0, 0, 0.05);
        .week_info {
            width: 14.3%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .day_content {
        margin: 2px 0;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        .day_info {
            width: 14.2%;
            // height: 28px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        //日期的基本宽高
        .day_content {
            width: 24px;
            height: 24px;
            display: flex;
            border-radius: 2px;
            font-size: 12px;
            align-items: center;
            justify-content: center;
        }
        .day_selected {
            background: #169bd5;
            color: white;
        }

        //鼠标禁用样式
        .cursor_dis {
            cursor: not-allowed;
        }

        //鼠标非禁用样式
        .cursor_p {
            cursor: pointer;
        }
    }
}
</style>